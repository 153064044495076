// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageTitle {
  text-align: left;
  padding: 20px;
  font-weight: 200;
  position: relative;
  left: 200px;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/pageHeader/pageHeader.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;AACJ","sourcesContent":[".PageTitle{\n    text-align: left;\n    padding: 20px;\n    font-weight: 200;\n    position: relative;\n    left: 200px;\n    top: 0;\n    // font-size: 10px !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
