// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableDiv {
  margin: 1rem;
  padding: 2rem;
  background-color: white;
  width: 72%;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.dataButton {
  background-color: white;
  border: 1px solid gray;
  color: gray;
  padding: 20px 15px;
  font-size: 16px;
  margin-top: 20px;
  width: 200px;
  display: flex;
  justify-content: space-between;
}

th {
  padding: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/displayCSV/displayCSV.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACQ,aAAA;EACA,uBAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACZ;;AAGA;EACI,uBAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EAEA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;AADJ;;AAIA;EACI,aAAA;AADJ","sourcesContent":[".tableDiv{\n    margin: 1rem;\n            padding: 2rem;\n            background-color: white;\n            width: 72%;\n            height: 80vh;\n            overflow-x: hidden;\n            overflow-y: auto;\n            text-align: justify;\n}\n\n\n.dataButton{\n    background-color: white;\n    border: 1px solid gray;\n    color: gray;\n    padding: 20px 15px;\n    font-size: 16px;\n    // align-items: flex-end;\n    margin-top: 20px;\n    width: 200px;\n    display: flex;\n    justify-content: space-between;\n    \n}\nth {\n    padding: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
