export const TelcoData= [['Days_Affected', 'Date'],
    ['', '2023-06-12'],
['1', '2023-06-08'],
['0.25', '2023-06-07'],
['0.04', '2023-06-07'],
['', '2023-06-07'],
['0.04', '2023-06-07'],
['0.04', '2023-06-07'],
['0.04', '2023-06-07'],
['0.04', '2023-06-07'],
['0.04', '2023-06-07'],
['0.04', '2023-06-07'],
['0.5', '2023-06-07'],
['0.08', '2023-06-05'],
['', '2023-06-05'],
['0.04', '2023-06-05'],
['0.04', '2023-05-31'],
['0.25', '2023-05-31'],
['', '2023-05-31'],
['0.04', '2023-05-31'],
['0.04', '2023-05-31'],
['0.04', '2023-05-31'],
['0.04', '2023-05-31'],
['0.04', '2023-05-31'],
['0.04', '2023-05-31'],
['', '2023-05-31']]
