// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metricTile {
  background-color: white;
  margin-top: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #78a9ff;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 95%;
  min-width: 1450px;
  margin-left: 2rem;
  max-width: 1500px;
}

.layout {
  width: 100%;
  display: flex;
  gap: 16px;
}

.grow1 {
  flex-grow: 1;
}

.metricValue {
  font-weight: 600;
}

.metricLink {
  text-decoration: none;
  font-size: large;
}`, "",{"version":3,"sources":["webpack://./src/components/metricTile/metricTile.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;EACA,UAAA;EACA,iBAAA;EAEA,iBAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,WAAA;EAEA,aAAA;EACA,SAAA;AADJ;;AAIE;EAAS,YAAA;AAAX;;AAEE;EACE,gBAAA;AACJ;;AAEE;EACE,qBAAA;EACA,gBAAA;AACJ","sourcesContent":[".metricTile{\n    background-color: white;\n    margin-top: 50px;\n    border-width: 1px;\n    border-style: solid;\n    border-color: #78a9ff;\n    align-items: center;\n    align-content: center;\n    text-align: center;\n    width: 95%;\n    min-width:1450px;\n\n    margin-left: 2rem;\n    max-width:1500px;\n  }\n\n.layout {\n    width: 100%;\n  \n    display: flex;\n    gap: 16px;\n  }\n  \n  .grow1 { flex-grow: 1; }\n\n  .metricValue{\n    font-weight: 600;\n  }\n\n  .metricLink{\n    text-decoration: none;\n    font-size: large;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
